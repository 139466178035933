// import { MapaComponent } from './../mapa/mapa.component';
import { Component, OnInit, Injectable, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

import { Filtro } from 'src/app/models/filtro';
import { GetPhotosService } from "../../services/get-photos.service";
import { ModalService } from '../../components/_modal/modal.service';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { PlatosService } from '../../services/platos.service';
import { ProductoService } from '../../services/producto.service';
import { UsuariosService } from '../../services/usuarios.service';
import { MapaService } from '../../services/mapa.service';
import { PublicacionService } from '../../services/publicacion.service';
import { FollowService } from '../../services/follow.service';
import { GLOBAL } from '../../services/global';
import { Router } from '@angular/router';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { DIR_DOCUMENT } from '@angular/cdk/bidi';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-seccion-resultados',
  templateUrl: './seccion-resultados.component.html',
  styleUrls: ['./seccion-resultados.component.css']
})
/**
 * 
 * CONTROLADOR PERTENECIENTE AL SLIDER DEL LADO DE LA IZQUIERDA
 * 
 * AÑADIR FOTO ESTANDAR NO IMAGE CUANDO MNO EXISTA UNA IMAGEN
 * 
 */
export class SeccionResultadosComponent implements OnInit {
  @ViewChild('listaFiltrosLocales', { static: false }) listaFiltrosLocales: ElementRef;
  //@ViewChild('mat-card', {static: false}) var : any;
  //QUITAN BASE64 DE SUBNOMBRE Y CREAR UN NUEVO CAMPO

  campo1: string;
  campo2: string;
  campo3: string;
  campo4: string;
  campo5: string;
  tempMarcadores = [];
  public selected: any;
  marcadores: any = [];
  public cerrado: any;
  public photosBase64: any;
  public fotoRestauranteLista: any;
  public parseNames = new Array();
  public i: any;
  public filterArray = [];
  public map = new Map();
  resultOk: boolean = true;
  t = [];
  urlM = "";
  stars: { value: number, filled: boolean }[] = [];
  mensajeBusqueda = "";
  page: number = 1;
  pagHasta: number = 0;
  numPages: number = 1;
  goScroll: boolean = false;
  cambiadoBusqueda = false;
  sizeBusqueda = 0;

  imgSeccion = [];
  @Input() item = '';
  @Input() searchBar = '';
  @Input() searchHastang = [];
  @Input() filtro: MatListOption[];
  @Input() nextPage = false;
  @Input() pos = null;

  @Input() textoPeticionFiltro: string = '';              // Este 
  @Output() changeMensajeBusqueda = new EventEmitter<string>();
  @Output() changeCloseSearch = new EventEmitter<boolean>();
  @Output() changeStatePage = new EventEmitter<boolean>();
  @Output() scrollData = new EventEmitter();

  constructor(
    private mapServices: MapaService,
    private gphotoService: GetPhotosService,
    private modalService: ModalService,
    private rService: RestauranteService,
    private pService: PlatosService,
    private proService: ProductoService,
    private uService: UsuariosService,
    private puService: PublicacionService,
    private foService: FollowService,
    private router: Router,
  ) {
    /**
     * MARCADORES CONTIENE EL NOMBRE DEL LOCAL EL CUAL SE ENVIARA AL BACKEND PARA ACCEDER A LAS FOTOS
     */

    this.campo1 = 'campo1';
    this.campo2 = 'campo2';
    this.campo3 = 'campo3';
    this.campo4 = 'campo4';
    this.campo5 = 'campo5';
  }

  /**
   * Modal para la reserva de restaurante
   * @param id 
   */
  openModal(id: string) {
    this.modalService.open(id);
  }
  /**
   * Modal para la reserva de restaurante
   * @param id 
   */
  closeModal(id: string) {
    this.modalService.close(id);
  }


  ngOnInit() {
    this.urlM = GLOBAL.url;
    //console.log("Es aquí: " + this.urlM);
    //HACE 2 PETICIONES ARREGLAR
    //RECOGE TODA LA INFO DE LOS LOCALES
    //ENTRA CADA VEZ QUE SE REPOSICIONA EL MAPA
    this.mapServices.currentMessage.subscribe(response => {
      this.marcadores = response;
      this.tempMarcadores = response;
      this.gphotoService.getPhotosPortadaNoAsync(this.marcadores).subscribe(f => this.getBase64(f));
      this.urlM = GLOBAL.url;
    });

    this.stars = [
      { value: 1, filled: false },
      { value: 2, filled: false },
      { value: 3, filled: false },
      { value: 4, filled: false },
      { value: 5, filled: false }
    ];
  };


  async ngOnChanges(changes: any) {
    var seccionItem = changes.item ? changes.item.currentValue : this.item;

    if (this.searchBar != "" && !changes.item) {
      this.changeCloseSearch.emit(false);
    }

    if (this.nextPage || changes.searchBar || changes.searchHastang || changes.item || changes.pos) {

      if (changes.searchBar || changes.searchHastang) {
        this.cambiadoBusqueda = true;
        if (changes.searchBar) {
          this.sizeBusqueda = changes.searchBar.currentValue.length;
        }
      } else {
        this.cambiadoBusqueda = false;
      }

      if (!this.nextPage || changes.item || changes.pos) {
        this.page = 1;
        this.numPages = 1;
        this.imgSeccion = [];
      } else {
        this.page++;
      }

      this.actualizarNavegacion();

      this.pagHasta = 0;

      let navegacion: any = localStorage.getItem("navegacion");

      if (navegacion != null) {
        navegacion = JSON.parse(navegacion);
        if (this.imgSeccion.length == 0) {
          this.pagHasta = navegacion.page;
          this.page = navegacion.page;
          this.numPages = navegacion.page;
          this.changeStatePage.emit(false);
          this.goScroll = true;
        }
      }

      if (changes.pos) {
        setTimeout(async () => {
          switch (seccionItem) {
            case 'carta': { await this.generarFichasSeccionesCarta(this.searchBar, this.searchHastang, this.filtro); break; }
            case 'productos': { await this.generarFichasSeccionesProductos(this.searchBar, this.searchHastang, this.filtro); break; }
            case 'restaurantes': { await this.generarFichasSeccionesRestaurantes(this.searchBar, this.searchHastang, this.filtro); break; }
            case 'usuarios': { await this.generarFichasSeccionesUsuarios(this.searchBar, this.searchHastang, this.filtro); break; }
          }
        }, 100);

      } else {
        switch (seccionItem) {
          case 'carta': { await this.generarFichasSeccionesCarta(this.searchBar, this.searchHastang, this.filtro); break; }
          case 'productos': { await this.generarFichasSeccionesProductos(this.searchBar, this.searchHastang, this.filtro); break; }
          case 'restaurantes': { await this.generarFichasSeccionesRestaurantes(this.searchBar, this.searchHastang, this.filtro); break; }
          case 'usuarios': { await this.generarFichasSeccionesUsuarios(this.searchBar, this.searchHastang, this.filtro); break; }
        }
      }
    }

    localStorage.setItem("filtro", JSON.stringify(this.filtro));
  }

  generarFichasSeccionesCarta = async (searchBar, searchHastang, filtros) => {
    if (this.page == 1 || this.page <= this.numPages) {
      await this.pService.getPhotosPlatos(searchBar, searchHastang, filtros, false, this.page, this.pagHasta, this.pos).subscribe(result => {
        if (result.result == "ok") {
          let noHacerNada = false;

          if (this.cambiadoBusqueda) {

            if (this.sizeBusqueda != searchBar.length) {
              noHacerNada = true;
            } else {
              this.imgSeccion = [];
            }

          }

          if (!noHacerNada) {
            this.numPages = result.platos.last_page;
            for (let valor of Object.values(result.platos.data)) {
              this.imgSeccion.push({
                thumbImage: GLOBAL.url + encodeURIComponent(valor['img'])
                , idSeccion: valor['id']
                , nombrePlato: valor['nombre_plato']
                , precioPlato: valor['precio_plato']
                , nombreRestaurante: valor['restaurante']['nombre']
                , totalSeguidores: valor['follows_count']
                , etiquetas: JSON.parse(valor['tags'])
                , slug: valor['slug']
                , otros_hashtag: valor['hashtag_de_producto']
                , imagen: valor['img']
              });
            }
            this.changeStatePage.emit(false);
            this.sendMensajeBusqueda();

            if (this.goScroll) {
              setTimeout(() => {
                this.scrollData.emit();
                this.goScroll = false;
              }, 10);
            }
          }


        }

      });
    }


  };


  generarFichasSeccionesProductos = async (searchBar, searchHastang, filtro) => {
    if (this.page == 1 || this.page <= this.numPages) {
      await this.proService.getPhotosProductos(searchBar, searchHastang, filtro, false, this.page, this.pagHasta, this.pos).subscribe(result => {
        if (result.result == "ok") {
          let noHacerNada = false;

          if (this.cambiadoBusqueda) {

            if (this.sizeBusqueda != searchBar.length) {
              noHacerNada = true;
            } else {
              this.imgSeccion = [];
            }

          }

          if (!noHacerNada) {
            this.numPages = result.productos.last_page;
            for (let valor of Object.values(result.productos.data)) {
              this.imgSeccion.push({
                thumbImage: GLOBAL.url + valor['img']
                , idSeccion: valor['id']
                , nombrePlato: valor['nombre']
                , precioPlato: valor['precio_producto']
                , nombreRestaurante: valor['fabricante']['nombre']
                , totalSeguidores: valor['follows_count']
                , etiquetas: valor['hashtag']
                , slug: valor['slug']
                , provincia: valor["fabricante"]['provincia_nombre']
                , municipio: valor["fabricante"]['municipio_nombre']
              });
            }
            this.changeStatePage.emit(false);
            this.sendMensajeBusqueda();
            if (this.goScroll) {
              setTimeout(() => {
                this.scrollData.emit();
                this.goScroll = false;
              }, 10);
            }
          }

        }
      });
    }
  };

  generarFichasSeccionesRestaurantes = async (searchBar, searchHastang, filtro) => {
    if (this.page == 1 || this.page <= this.numPages) {

      await this.rService.getPhotosRestaurantes(searchBar, searchHastang, filtro, false, this.page, this.pagHasta, this.pos).subscribe(result => {
        if (result.result == "ok") {
          let noHacerNada = false;

          if (this.cambiadoBusqueda) {

            if (this.sizeBusqueda != searchBar.length) {
              noHacerNada = true;
            } else {
              this.imgSeccion = [];
            }

          }

          if (!noHacerNada) {
            let data;
            if (result.establecimientos.data != undefined) {
              this.numPages = result.establecimientos.last_page;
              data = result.establecimientos.data;
            } else {
              this.page = 1;
              this.numPages = 1;
              this.imgSeccion = [];
              data = result.establecimientos;
            }
            for (let valor of Object.values(data)) {
              this.imgSeccion.push({
                thumbImage: GLOBAL.url + valor['fotoPerfil']
                , idSeccion: valor['id']
                , nombreRestaurante: valor['nombre']
                , tipoRestaurante: valor['tipo_local']
                , tipoComidas: valor['tipo_comidas']
                , direccionRest: valor['direccion']
                , slug: valor['slug']
              });
            }
            this.changeStatePage.emit(false);
            this.sendMensajeBusqueda();
            if (this.goScroll) {
              setTimeout(() => {
                this.scrollData.emit();
                this.goScroll = false;
              }, 10);
            }
          }

        }

      });
    }

  };

  generarFichasSeccionesUsuarios = async (searchBar, searchHastang, filtro) => {
    await this.uService.getPhotosUsuarios(searchBar, searchHastang, filtro, false, this.page).subscribe(result => {
      if (result.result == "ok") {
        if (this.cambiadoBusqueda) {
          this.imgSeccion = [];
        }
        for (let valor of Object.values(result.publicaciones.data)) {
          let model = {
            idSeccion: valor['id']
            , nombreRestaurante: valor['restaurante']['nombre']
            , plato: valor['plato']
            , nombreUsuario: valor['user']['name']
            , apellidosUsuario: valor['user']['apellidos']
            , descripcion: valor['descripcion']
            , hashtag: valor['hashtag']
            , hashtag_normal: valor['tipo_etiqueta']
            , raiting: valor['calificacion']
            , slug: valor['slug']
          }

          if (valor["img"] == null || valor["img"] == undefined) {
            model["thumbImage"] = null;
          } else {
            model["thumbImage"] = GLOBAL.url + valor['img'];
          }

          this.imgSeccion.push(model);
        }
        this.sendMensajeBusqueda();
      }

    });
  };

  getFilledStars(calificacion: number) {
    const stars = [
      { value: 1, filled: false },
      { value: 2, filled: false },
      { value: 3, filled: false },
      { value: 4, filled: false },
      { value: 5, filled: false }
    ];

    for (let i = 0; i < stars.length; i++) {
      stars[i].filled = i < calificacion;
    }

    return stars;
  }

  goDetailSeccion(idTipo, item) {

    switch (item) {
      case 'carta': { this.router.navigate(['plato/' + idTipo]); break; }
      case 'productos': { this.router.navigate(['producto/' + idTipo]); break; }
      case 'restaurantes': { this.router.navigate(['establecimiento/' + idTipo]); break; }
      case 'usuarios': { this.router.navigate(['publicacion/' + idTipo]); break; }
    }
  }



  getID() {
    //console.log("----------------->" + id);
  }


  getBase64(f: any) {

    if (f == "*** no data provide ***") {

      console.log(f);

    } else {

      for (let image in f) {//RECORRE LAS IMAGENES DISPONIBLE EN BASE64

        for (let index = 0; index < this.marcadores.length; index++) {

          if (this.marcadores[index]["id"] == image) {
            /**
             * safe decode un SRC
             */
            this.marcadores[index]["portada"] = f[image];
          }
        }
      }
    }
  }

  trackByMarcador(index: number, marcador: any): string {
    return marcador.id;
  }

  sendMensajeBusqueda = () => {

    this.mensajeBusqueda = "";

    if (this.searchBar.length > 0) {
      this.mensajeBusqueda += "Estas buscando: <span style='color:#E35100;'>" + this.searchBar + "</span>";
    }

    if (this.imgSeccion.length == 0) {
      this.mensajeBusqueda += "<br>No se ha encontrado nada con la busqueda realizada";
    }

    this.changeMensajeBusqueda.emit(this.mensajeBusqueda);
  }

  actualizarNavegacion() {
    let navegacion: any = localStorage.getItem("navegacion");

    if (navegacion != null) {
      navegacion = JSON.parse(navegacion);
      navegacion.page = this.page;
      navegacion.searchBar = this.searchBar;
      localStorage.setItem("navegacion", JSON.stringify(navegacion));
    }
  }
}



